import React from 'react';
import Layout from './../components/Layout';
import PostCard from '../components/PostCard';
import { graphql } from 'gatsby';

// Import styling components
import styled from 'styled-components';

// Styled components
const Heading = styled.h1`
	margin-bottom: 0;
	padding: 1rem 1rem 0 1rem;
`;

const PostCardContainer = styled.div`
	box-sizing: border-box;
	display: flex;
		flex-wrap: wrap;
	margin-top: 1rem;

	.post-card {
		width: calc(33.3% - 1rem);
	}

	@media (max-width: 620px) {
		.post-card { width: 100%; }
	}
`;

// Query for posts
export const data = graphql`
	query {
		cms {
			posts(orderBy: postedat_DESC) {
				id
				title
				body
				postedat
			}
		}
	}
`;

export default ({data}) => (
	<Layout pageTitle="Blog">
		<Heading>All Posts</Heading>
		<PostCardContainer>
			{data.cms.posts.map(post => {
				return <PostCard title={post.title} body={post.body} id={post.id} key={post.id} postedAt={post.postedat}/>
			})}
		</PostCardContainer>
	</Layout>
);